import React, { useState } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CCardTitle,
  CSpinner,
  CAlert,
  CCardText,
} from "@coreui/react";
import { useLocation, useNavigate } from "react-router-dom";
import enterpriseProfileImg from "../assets/enterprise_profile.png";
import ValidateEmail from "../services/emailvalidation.service";

import CIcon from "@coreui/icons-react";
import {
  cibAdguard,
  cilAsterisk,
  cilCheckCircle,
  cilCode,
  cilLockLocked,
  cilLowVision,
  cilUser,
} from "@coreui/icons";
import loginService from "../services/login.service";
import validator from "validator";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const location = useLocation();
  const [errorMsg, setErrorMsg] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmpassVisible, setConfirmPassVisible] = useState(false);

  let navigate = useNavigate();

  const handleForgotPassword = async (e) => {
    e.preventDefault();

    const isValid = isValidEmail(email);

    if (isValid) {
      setIsLoading(true);

      let res = await loginService.forgotPassword(email);
      if (res.data.CodeDeliveryDetails) {
        setOtpSent(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } else {
      setErrorMsg("Please enter a valid email address");
      setIsLoading(false);
    }
  };

  const handleResetPassword = async (e) => {
    setErrorMsg();
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setErrorMsg("Password and Confirm Password sholud be the same");
      return;
    }
    if (
      email.length == 0 ||
      validator.isEmail(email) ||
      code.length == 0 ||
      !isNaN(code) ||
      newPassword.length == 0 ||
      validator.isStrongPassword(newPassword, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      }) ||
      confirmPassword.length == 0 ||
      isNaN(confirmPassword)
    ) {
      setIsLoading(true);
      let res = await loginService.resetPassword(email, code, confirmPassword);
      // console.log("response", res)
      if (Object.keys(res.data).length != 0) {
        setErrorMsg(res.data.message);
      } else {
        navigate("/");
        setOtpSent(false);
      }
      setIsLoading(false);
    }
  };
  function isValidEmail(emailText) {
    return /\S+@\S+\.\S+/.test(emailText);
  }
  return (
    <div className="bg-setup-img min-vh-100 d-flex flex-row align-items-center">
      <div className="box w-100">
        <CContainer>
          <CRow className="justify-content-center p-0">
            <CCol
              md={8}
              className="d-flex tab-Shadow p-0 rounded"
              style={{
                width: "59%",
                borderRadius: "5px",
                borderRadius: "5px !important",
              }}
            >
              <CCardGroup>
                <CCard className="px-4 py-2" style={{ width: "700px" }}>
                  <CCardBody>
                    <CForm
                      style={{ display: "block" }}
                      onSubmit={
                        otpSent ? handleResetPassword : handleForgotPassword
                      }
                      autoComplete="off"
                    >
                      <CCardTitle style={{ fontSize: 20 }}>
                        DataGeir™ Recover Account
                      </CCardTitle>
                      <CCardText className="">
                        {/* We will send you a verification code on this email-id */}
                        Enter your email address and we'll send you a
                        verification code to reset your password.
                      </CCardText>
                      <CInputGroup className="mt-0 ">
                        <CInputGroupText className="bg-white">
                          <CIcon icon={cilUser} size="sm" className="color" />
                        </CInputGroupText>
                        <CFormInput
                          size="sm"
                          disabled={otpSent}
                          type={"email"}
                          value={email}
                          name={"email"}
                          placeholder={"Email address"}
                          required={true}
                          onChange={(e) => {
                            setEmail(e.target.value);
                            setErrorMsg();
                          }}
                          autoComplete={"off"}
                          // valid
                          // feedback
                        />
                      </CInputGroup>
                      {otpSent ? (
                        <>
                          <CInputGroup className="mt-2">
                            <CInputGroupText className="bg-white">
                              <CIcon
                                icon={cibAdguard}
                                size="sm"
                                className="color"
                              />
                            </CInputGroupText>
                            <CFormInput
                              size="sm"
                              type={"number"}
                              value={code}
                              name={"code"}
                              placeholder={"Enter verification code"}
                              required={true}
                              onChange={(e) => {
                                setCode(e.target.value);
                                setErrorMsg();
                              }}
                              autoComplete={"off"}
                              // valid
                              // feedback
                            />
                          </CInputGroup>
                          <CInputGroup className="mt-2 position-relative">
                            <CInputGroupText className="bg-white">
                              <CIcon
                                icon={cilLockLocked}
                                size="sm"
                                className="color"
                              />
                            </CInputGroupText>
                            <CFormInput
                              size="sm"
                              type={!passwordVisible?"password":"text"}
                              value={newPassword}
                              name={"newPass"}
                              placeholder={"Create new password"}
                              required={true}
                              onChange={(e) => {
                                setNewPassword(e.target.value);
                                setErrorMsg();
                              }}
                              autoComplete={"off"}
                              // valid
                              // feedback
                            />
                            <CIcon
                              className={`node ${
                                passwordVisible ? "text-primary" : ""
                              }`}
                              style={{
                                position: "absolute",
                                right: "5px",
                                top: "7px",
                                zIndex: 200,
                              }}
                              icon={cilLowVision}
                              onClick={() =>
                                setPasswordVisible(!passwordVisible)
                              }
                            />
                          </CInputGroup>
                          <CInputGroup className="mt-2 position-relative">
                            <CInputGroupText className="bg-white">
                              <CIcon
                                icon={cilLockLocked}
                                size="sm"
                                className="color"
                              />
                            </CInputGroupText>
                            <CFormInput
                              size="sm"
                              type={!confirmpassVisible?"password":"text"}
                              value={confirmPassword}
                              name={"confirm"}
                              placeholder={"Confirm password"}
                              required={true}
                              onChange={(e) => {
                                setConfirmPassword(e.target.value);
                                setErrorMsg();
                              }}
                              autoComplete={"off"}
                              // valid
                              // feedback
                            />
                            <CIcon
                              className={`node ${
                                confirmpassVisible ? "text-primary" : ""
                              }`}
                              style={{
                                position: "absolute",
                                right: "5px",
                                top: "7px",
                                zIndex: 200,
                              }}
                              icon={cilLowVision}
                              onClick={() =>
                                setConfirmPassVisible(!confirmpassVisible)
                              }
                            />
                          </CInputGroup>
                        </>
                      ) : null}
                      <div align="left">
                        <span className="text-danger font-sm">{errorMsg}</span>
                      </div>
                      <CRow className="mt-3 d-flex justify-content-start pe-3 mx-5 ms-0">
                        <CCol className="m-0 p-0 max-width-fit ">
                          <CButton
                            color="primary"
                            size="sm"
                            className=" m-0"
                            variant="outline"
                            onClick={() => {
                              navigate("/");
                            }}
                          >
                            Cancel
                          </CButton>
                        </CCol>
                        <CCol className="m-0 pe-2">
                          <CButton
                            // disabled={disableButton}
                            color="primary"
                            type="submit"
                            size="sm"
                            className="d-flex gap-1 align-items-center m-0"
                            variant="outline"
                          >
                            {isLoading ? (
                              <CSpinner
                                component="span"
                                size="sm"
                                aria-hidden="true"
                              />
                            ) : null}
                            {otpSent ? "Reset Password" : "Send OTP"}
                          </CButton>
                        </CCol>
                      </CRow>
                      <br></br>
                    </CForm>
                  </CCardBody>
                </CCard>
                <CCard
                  className="text-white d-flex align-items-center rounded-0"
                  style={{ width: "40%", backgroundColor: "#faf8fd" }}
                >
                  <img
                    className="mt-5"
                    src={enterpriseProfileImg}
                    width={100}
                  />
                  <div className="d-flex justify-content-center"></div>
                  <CCardBody className="text-center">
                    <div></div>
                  </CCardBody>
                  <div>
                    {otpSent ? (
                      <div>
                        <CAlert
                          color="success"
                          className="d-flex align-items-center mx-2"
                          dismissible
                        >
                          <CIcon
                            icon={cilCheckCircle}
                            className="flex-shrink-0 me-2"
                            width={24}
                            height={24}
                          />
                          <div>Verification Code sent to your email</div>
                        </CAlert>
                      </div>
                    ) : null}
                  </div>
                </CCard>
              </CCardGroup>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    </div>
  );
};

export default ForgotPassword;
