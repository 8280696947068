import axios from "axios";
const config = require("../config/config");
const API_URL = config.api_url;

class LoginService {
  login(username, password, type) {
    try {
      var data = JSON.stringify({
        username: username,
        password: password,
      });

      var config = {
        method: "post",
        url: API_URL + "/v1/auth/login/" + type,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      return axios(config)
        .then(function (response) {
          // console.log(JSON.stringify(response.data));
          sessionStorage.clear();
          return response;
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  forgotPassword(username) {
    try {
      var data = JSON.stringify({
        username: username,
      });

      var config = {
        method: "post",
        url: API_URL + "/v1/auth/login/forgot",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      return axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          return response;
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
      return error;
    }
  }
  resetPassword(username, verificationCode, newPassword) {
    try {
      var data = JSON.stringify({
        username: username,
        verificationCode: verificationCode,
        newPassword: newPassword,
      });

      var config = {
        method: "post",
        url: API_URL + "/v1/auth/login/reset",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      return axios(config)
        .then(function (response) {
          // console.log(JSON.stringify(response.data));
          return response;
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  //looker login service

  lookerLogin(baseUrl, clientId, clientSecret) {
    try {
      var data = JSON.stringify({
        baseUrl: baseUrl,
        clientId: clientId,
        clientSecret: clientSecret,
      });

      var config = {
        method: "post",
        url: API_URL + "/v1/auth/login/lookeruser",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      return axios(config)
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          console.log(error);
          throw error;
        });
    } catch (error) {
      console.log(error);
      return error;
    }
  }
}

export default new LoginService();
