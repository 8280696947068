
// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'
//  import './index.css';
import appStore from './redux/appStore'
import { PersistGate } from 'redux-persist/integration/react'
import configStore from './redux/configureStore'
import { GoogleOAuthProvider } from '@react-oauth/google';
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <GoogleOAuthProvider clientId="971708092056-ne2m0vkq9qjh195s50che03rh2q5fm4m.apps.googleusercontent.com">
  <Provider store={appStore}>
    <App />
  </Provider>
  </GoogleOAuthProvider>
  // document.getElementById('root'),


    // <Provider store={configStore.store}>
    //   <PersistGate loading={null} persistor={configStore.persistor}>
    //     <App />
    //   </PersistGate>
    // </Provider>
 
)

reportWebVitals()
