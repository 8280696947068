import axios from "axios";
const config = require('../config/config');
const API_URL = config.api_url

class AllDomainListService {

  getDomains(request) {
    // console.log("API url:", API_URL)

    try {
        var data = JSON.stringify({
          "request_type": "DOMAIN"
        });
        
        var config = {
          method: 'post',
          url: 'http://localhost:8080/v1/postgres/signup_details/view',
          headers: { 
            'Content-Type': 'application/json'
          },
          data : data
        };
        
        return axios(config)
        .then(function (response) {
          // console.log(JSON.stringify(response.data));
          return response.data
        })
        .catch(function (error) {
          console.log(error);
        });
        
    } catch (error) {
      console.log(error);
      return error;
    }

  }
}

export default new AllDomainListService();