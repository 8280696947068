import "./App.css";
import "../src/scss/style.scss";
import "@coreui/coreui/dist/css/coreui.min.css";
import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes, Navigate, Form } from "react-router-dom";
import SignupPage from "./pages/Signup";
import LoginPage from "./pages/Login";
import AdminLoginPage from "./pages/AdminLogin";
import "react-loading-skeleton/dist/skeleton.css";
import AdminLogin from "./components/AdminLogin";
import ForgotPassword from "./components/ForgotPassword";
const AppLayout = React.lazy(() => import("./layout/AppLayout"));
const CostnomicsLayout = React.lazy(() => import("./layout/CostnomicsLayout"));
const EnterpriseSetUp = React.lazy(() =>
  import("./components/EnterpriseAccountSetup")
);

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);
function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={loading}>
        <Routes>
          <Route path="/" name="Login" element={<LoginPage />} />
          <Route
            path="/admin-login"
            name="AdminLogin"
            element={<AdminLoginPage />}
          />
          {/* <Route path="/" name="Login" element={<LoginPage setAccessToken={accessToken} setIdToken={idToken}/>} /> */}
          <Route path="/signup" element={<SignupPage />} />
          {/* <Route path="/home" element={<HomePage />} /> */}
          <Route path="/account-setup" element={<EnterpriseSetUp />} />
          <Route path="/datageir/*" name="Datageir" element={<AppLayout />} />
          <Route
            path="/costnomics/*"
            name="Costnomics"
            element={<CostnomicsLayout />}
          />
          <Route path="/forgot-password" element={<ForgotPassword />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
