import { React, useState, useEffect } from "react";
// import { cilCheckCircle } from '@coreui/icons-react'
// import { CIcon } from '@coreui/icons-react';
import CIcon from "@coreui/icons-react";
import * as icon from "@coreui/icons";
import { cilCheckCircle } from "@coreui/icons";
import { accountSetupFields } from "../constants/formFields";
import enterpriseProfileImg from "../assets/enterprise_profile.png";
import SignupService from "../services/signup.service";
import requestflowService from "../services/requestflow.service";
import getEnterpriseService from "../services/getEnterprise.service";
import EnterpriseService from "../services/enterprise.list.service";
import FormAction from "./FormAction";
import ValidateEmail from "../services/emailvalidation.service";
import Input from "./Input";
import "../../src/scss/style.scss";
import {
  CAlert,
  CSpinner,
  CCardTitle,
  CButton,
  CContainer,
  CRow,
  CCol,
  CCard,
  CCardBody,
  CForm,
  CCardGroup,
  CFormCheck,
  CToast,
  CToastBody,
} from "@coreui/react";
import validator from "validator";
import signup_licenseService from "../services/signup_license.service";
import { useLocation, useNavigate } from "react-router-dom";
const fields = accountSetupFields;
let fieldsState = {};

fields.forEach((field) => (fieldsState[field.id] = ""));

export default function EnterpriseSetUp(props) {
  const [signupState, setSignupState] = useState(fieldsState);
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isFromSubmit, setIsFromSubmit] = useState(false);
  const [isEmailExists, setIsEmailExists] = useState(false);
  const [isEnterpriseExists, setIsEnterpriseExists] = useState(false);
  const [signupRes, setSignupRes] = useState(false);
  const [isAllClicked, setIsAllClicked] = useState();
  const [costnomicsCheckd, setCostnomicsCheckd] = useState();
  const [hawkeyeChecked, setHawkeyeChecked] = useState();
  const [disableButton, setDisableButton] = useState(true);
  const [enterprises, setEnterprises] = useState([]);

  const location = useLocation();

  let navigate = useNavigate();

  // console.log("email", location.state.email)
  useEffect(() => {}, [costnomicsCheckd, hawkeyeChecked]);
  useEffect(() => {
    getEnterpriseRequests();
  }, []);
  const getEnterpriseRequests = async () => {
    const response = await EnterpriseService.getAllEnterprises();
    if (response != undefined) {
      // console.log(response)
      setEnterprises(response);
    } else {
      console.log("No data found");
    }
  };
  const handleChange = (e) => {
    setDisableButton(false);
    setSignupRes(false);
    setSignupState({ ...signupState, [e.target.id]: e.target.value });
    setVisible(false);
    setIsEmailExists(true);
    let uniq = enterprises.find((el) => {
      if (e.target.id === "name")
        return (
          el.service_name.toLowerCase() ==
          e.target.value.toLowerCase().replace(/\s+/g, " ").trim()
        );
    });
    if (uniq === undefined) {
      setIsEnterpriseExists(true);
    } else {
      setIsEnterpriseExists(false);
    }
  };
  const handleSubmit = (e) => {
    // console.log("handleSubmit call");

    setDisableButton(true);
    e.preventDefault();
    setIsFromSubmit(true);
    setIsEmailExists(true);
    let uniq = enterprises.find((el) => {
      return (
        el.service_name.toLowerCase() ==
        signupState["name"].toLowerCase().replace(/\s+/g, " ").trim()
      );
    });
    // console.log("uniq", uniq);
    if (uniq === undefined) {
      setIsEnterpriseExists(true);
      setIsLoading(true);

      createAccount();
    } else {
      setIsEnterpriseExists(false);
      setIsLoading(false);
    }
  };
  // console.log(isEnterpriseExists);
  const emailvalidation = async () => {
    var email = signupState["email"].toLowerCase();
    var emailresp = await ValidateEmail.checkEmail(email);
    // console.log("emailresp", emailresp);
    return emailresp;
  };

  //handle Signup API Integration here
  const createAccount = async () => {
    // console.log("create account call", signupState);
    var name = signupState["name"].replace(/\s+/g, " ").trim();
    var owner = signupState["owner"].replace(/\s+/g, " ").trim();
    var email = location.state.email;

    // var email = signupState["email"].toLowerCase();
    var address = signupState["address"].replace(/\s+/g, " ").trim();
    var password = "";

    if (
      name.length == 0 ||
      !isNaN(name) ||
      owner.length == 0 ||
      !isNaN(owner) ||
      email.length == 0 ||
      !validator.isEmail(email) ||
      address.length == 0 ||
      !isNaN(address)
    ) {
      setIsLoading(false);
      return;
    }

    var reponse = await SignupService.signUpUser(
      name,
      null,
      owner,
      email,
      address,
      password,
      "ENTERPRISE",
      null,
      "google"
    );
    setVisible(true);
    setIsFromSubmit(false);
    signupState["name"] = "";
    signupState["owner"] = "";
    signupState["address"] = "";
    signupState["email"] = "";
    signupState["password"] = "";
    signupState["confirm password"] = "";
    setIsLoading(false);
    setCostnomicsCheckd(false);
    setHawkeyeChecked(false);
    setSignupState({ ...signupState });
    if (reponse != undefined) {
      var repo = await signup_licenseService.signUpUserLicense(email, ["1"]);
      setDisableButton(false);
      setIsLoading(false);
      setSignupRes(true);
      var enterprise = await getEnterpriseService.getEnterprise(email);
      var enterprise_uuid = enterprise[0].uuid;
      // console.log("Enterprise UUID**", enterprise[0].uuid);
      var action_id = 1;
      var actionFlow = await requestflowService.registerRequest(
        action_id,
        enterprise_uuid
      );
    } else {
      setSignupRes(false);
      setIsLoading(false);
    }
    setIsLoading(false);

    setTimeout(function () {
      setSignupRes(false);
      navigate("/");
    }, 3000);
  };

  return (
    <div className="bg-setup-img min-vh-100 d-flex flex-row align-items-center">
      <div className="box w-100">
        <CContainer>
          <CRow className="justify-content-center p-0">
            <CCol
              md={8}
              className="d-flex tab-Shadow p-0 rounded"
              style={{
                width: "59%",
                borderRadius: "5px",
                borderRadius: "5px !important",
              }}
            >
              <CCardGroup>
                <CCard className="px-4 py-2" style={{ width: "600px" }}>
                  <CCardBody>
                    <CForm
                      style={{ display: "block" }}
                      onSubmit={handleSubmit}
                      autoComplete="off"
                    >
                      {/* <CCardTitle style={{ fontSize: 20 }}>
                      Complete your Enterprise account setup on DataGeir™{" "}
                    </CCardTitle> */}
                      <CCardTitle style={{ fontSize: 20 }}>
                        DataGeir™ Account Setup
                      </CCardTitle>
                      {/* <CCardTitle style={{ fontSize: 22 }}>Sign up to create an account</CCardTitle> */}
                      <p className="text-medium-emphasis">
                        Complete your Enterprise account setup
                      </p>
                      {fields.map((field) => (
                        <>
                          <Input
                            key={field.id}
                            handleChange={handleChange}
                            value={signupState[field.id]}
                            labelText={field.labelText}
                            labelFor={field.labelFor}
                            id={field.id}
                            name={field.name}
                            type={field.type}
                            //isRequired={field.isRequired}
                            placeholder={field.placeholder}
                            icon={field.icon}
                            autoComplete={field.autoComplete}
                          />

                          {isFromSubmit && signupState[field.id].length == 0 ? (
                            <span className="text-danger">{`Please enter ${field.id}`}</span>
                          ) : isFromSubmit &&
                            field.id == "name" &&
                            !isNaN(signupState[field.id]) ? (
                            <span className="text-danger">{`Please enter a valid ${field.id}`}</span>
                          ) : isFromSubmit &&
                            field.id == "name" &&
                            isEnterpriseExists == false ? (
                            <span className="text-danger">{`This Enterprise already Exists`}</span>
                          ) : isFromSubmit &&
                            field.id == "owner" &&
                            !isNaN(signupState[field.id]) ? (
                            <span className="text-danger">{`Please enter a valid ${field.id}`}</span>
                          ) : isFromSubmit &&
                            field.id == "address" &&
                            !isNaN(signupState[field.id]) ? (
                            <span className="text-danger">{`Please enter a valid ${field.id}`}</span>
                          ) : (
                            ""
                          )}
                        </>
                      ))}
                      <br />
                      <CFormCheck
                        className="mt-1 mb-0"
                        inline
                        id="inlineCheckbox2"
                        value="1"
                        label="CostNomics"
                        checked={true}
                      />
                      <br />
                      <CRow className="mt-3 d-flex justify-content-start pe-3 mx-5 ms-0">
                        <CCol
                          className="m-0 p-0 max-width-fit "
                          style={{ width: "320px" }}
                        >
                          <CButton
                            color="primary"
                            size="sm"
                            className="px-3 m-0"
                            variant="outline"
                            onClick={() => {
                              setSignupRes(false);
                              setIsFromSubmit(false);
                              // props.handleActiveKey(0);
                              navigate("/");
                            }}
                          >
                            Cancel
                          </CButton>
                        </CCol>
                        <CCol className="m-0 pe-4">
                          <CButton
                            disabled={disableButton}
                            color="primary"
                            type="submit"
                            size="sm"
                            className="d-flex gap-2 align-items-center px-4 m-0"
                            variant="outline"
                          >
                            {isLoading ? (
                              <CSpinner
                                component="span"
                                size="sm"
                                aria-hidden="true"
                              />
                            ) : null}
                            Save
                          </CButton>
                        </CCol>
                      </CRow>
                      <br></br>
                    </CForm>
                  </CCardBody>
                </CCard>
                <CCard
                  className="text-white d-flex align-items-center rounded-0"
                  style={{ width: "44%", backgroundColor: "#faf8fd" }}
                >
                  <img
                    className="mt-5"
                    src={enterpriseProfileImg}
                    width={100}
                  />
                  <p className="text-black font-lg mt-3 mb-0">
                    {location.state.name}
                  </p>
                  <p className="text-medium-emphasis ">
                    {location.state.email}
                  </p>
                  <div className="d-flex justify-content-center"></div>
                  <CCardBody className="text-center">
                    <div></div>
                  </CCardBody>
                  <div>
                    {signupRes ? (
                      <div>
                        <CAlert
                          color="success"
                          className="d-flex align-items-center mx-2"
                          dismissible
                        >
                          <CIcon
                            icon={cilCheckCircle}
                            className="flex-shrink-0 me-2"
                            width={24}
                            height={24}
                          />
                          <div>
                            Account setup successful. Awaiting admin approval.
                          </div>
                        </CAlert>
                      </div>
                    ) : null}
                  </div>
                </CCard>
              </CCardGroup>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    </div>
  );
}
