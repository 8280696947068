import axios from "axios";
const config = require("../config/config");
const API_URL = config.api_url;

class SignUpLicenseService {
  signUpUserLicense(email, product) {
    // console.log("API url:",API_URL)

    try {
      var data = JSON.stringify({
        email: email,
        product: product,
      });

      var config = {
        method: "post",
        url: API_URL + "/v1/postgres/signup_license_details",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      // console.log("API config:",config)

      return axios(config)
        .then(function (response) {
          // console.log(JSON.stringify(response.data));
          return response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
      return error;
    }
  }
  CreateUserLicense(
    uuid,
    Accounts,
    DSUser,
    Domain,
    Users,
    startDate,
    endDate,
    plan
  ) {
    // console.log("API url:",API_URL)

    try {
      var data = JSON.stringify({
        uuid,
        properties: JSON.stringify({
          start_date: startDate,
          end_date: endDate,
          accounts: Accounts,
          enterprise_users: Users,
          domain_owner: Domain,
          data_source_user: DSUser,
          plan: plan,
        }),
      });

      var config = {
        method: "post",
        url: API_URL + "/v1/postgres/signup_details/create_license",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      // console.log("API config:",config)

      return axios(config)
        .then(function (response) {
          // console.log(JSON.stringify(response.data));
          return response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
      return error;
    }
  }
  UpdateUserLicense(
    uuid,
    Accounts,
    DSUser,
    Domain,
    Users,
    startDate,
    endDate,
    plan
  ) {
    // console.log("API url:",API_URL)

    try {
      var data = JSON.stringify({
        uuid,
        properties: JSON.stringify({
          start_date: startDate,
          end_date: endDate,
          accounts: Accounts,
          enterprise_users: Users,
          domain_owner: Domain,
          data_source_user: DSUser,
          plan,
        }),
      });

      var config = {
        method: "post",
        url: API_URL + "/v1/postgres/signup_details/update_license",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      // console.log("API config:",config)

      return axios(config)
        .then(function (response) {
          // console.log(JSON.stringify(response.data));
          return response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
      return error;
    }
  }
  GetUserLicense(uuid) {
    // console.log("API url:",API_URL)

    try {
      var data = JSON.stringify({
        uuid,
      });

      var config = {
        method: "post",
        url: API_URL + "/v1/postgres/signup_details/get_license",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      // console.log("API config:",config)

      return axios(config)
        .then(function (response) {
          // console.log(JSON.stringify(response.data));
          return response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
      return error;
    }
  }
}

export default new SignUpLicenseService();
