import { React, useEffect, useState } from "react";
import {
  CAlert,
  CSpinner,
  CNav,
  CTabPane,
  CTabContent,
  CNavItem,
  CNavTitle,
  CNavLink,
  CCardTitle,
  CButton,
  CContainer,
  CRow,
  CCol,
  CCard,
  CCardBody,
  CForm,
  CInputGroup,
  CInputGroupText,
  CFormInput,
  CCardGroup,
} from "@coreui/react";
import { Link } from "react-router-dom";
import { loginFields } from "../constants/formFields";
import { useNavigate } from "react-router-dom";
import LoginService from "../../src/services/login.service";
import AdminLogin from "../components/AdminLogin";
import SignupPage from "./Signup";
import UserLogin from "../components/UserLogin";
import UseToken from "../components/App/UseToken";
import UserDetailsService from "../services/user.detail.service";
import alldomainsListService from "../services/alldomains.list.service";
import requestflowService from "../services/requestflow.service";
import { UseUserDetails } from "../components/App/useUserDetails";
//redux import
import { useDispatch } from "react-redux";
import { updateUserUuid } from "../redux/userDetail";
import UseUUID from "../components/App/UseUUID";
import loginImg from "../assets/login_img.png";

import "../../src/scss/_custom.scss";

const fields = loginFields;
let fieldsState = {};

const loginType = {
  USER: "user",
  ADMIN: "admin",
};
const ServiceType = {
  ENTERPRISE: "ENTERPRISE",
  DOMAIN: "DOMAIN",
  PRODUCT: "PRODUCT",
  ENTERPRISE_USER: "ENTERPRISER_USER",
  DATA_SOUCE_USER: "DATA_SOURCE_USER",
};

fields.forEach((field) => (fieldsState[field.id] = ""));

export default function AdminLoginPage() {
  const dispatch = useDispatch();

  let navigate = useNavigate();
  const { accessToken, setAccessToken } = UseToken();
  const { idToken, setIdToken } = UseToken();
  const { UUID, setUUID } = UseUUID();

  const [loginState, setLoginState] = useState(fieldsState);
  const [activeKey, setActiveKey] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const { userDetails, setUserDetails } = UseUserDetails();
  const [licenseExpired, setLicenseExpired] = useState(false);
  const [userNotExistError, setUserNotExistError] = useState(false);
  const [errorVisible, setErrorVisible] = useState(false);
  const [rejectVisible, setRejectVisible] = useState(false);
  const [userExistVisible, setUserExistVisible] = useState(false);

  const handleChange = (e) => {
    setErrorVisible(false);
    setLoginState({ ...loginState, [e.target.id]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    authenticateUser();
  };
  useEffect(() => {
    setLicenseExpired(false);
  }, []);
  const authenticateUser = async () => {
    setIsLoading(true);

    var email = loginState["email-address"].toLowerCase();
    var passsword = loginState["password"];
    var response;

    response = await LoginService.login(email, passsword, loginType.ADMIN);
    // console.log("admin respo", response);

    setUserDetails("", "", "ADMIN", "", "");

    if (response != undefined) {
      if (response.data.accessToken) {
        setAccessToken(response.data.accessToken);
        setIdToken(response.data.idToken);
        let path = "/datageir/approved-enterprises";
        navigate(path);
      } else {
        setErrorVisible(true);
      }
    } else {
      setErrorVisible(true);
    }
    setIsLoading(false);
  };
  return (
    <div className="bg-setup-img min-vh-100 d-flex flex-row align-items-center">
      <CContainer style={{ width: "55% " }}>
        <CRow className="d-flex justify-content-center">
          <CCol md={7} className="m-0 p-0">
            <UserLogin
              fields={fields}
              loginState={loginState}
              handleSubmit={handleSubmit}
              handleChange={handleChange}
              isLoading={isLoading}
              errorVisible={errorVisible}
              setErrorVisible={setErrorVisible}
              loginType={loginType.ADMIN}
              visible={visible}
              setVisible={setVisible}
              rejectVisible={rejectVisible}
              setRejectVisible={setRejectVisible}
              setUserNotExistError={setUserNotExistError}
              userNotExistError={userNotExistError}
              userExistVisible={userExistVisible}
              setUserExistVisible={setUserExistVisible}
              licenseExpired={licenseExpired}
              setLicenseExpired={setLicenseExpired}
            />
          </CCol>
          <CCol md={5} className="m-0 p-0">
            <CCard
              className="py-2 rounded-0 me-0 "
              style={{ height: "100%", backgroundColor: "#faf8fd" }}
            >
              <CCardBody>
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <img src={loginImg} style={{ width: "190px" }} />

                  <a
                    href="https://mail.google.com/mail/u/0/?fs=1&to=info@atgeirsolutions.com&tf=cm"
                    target="_blank"
                    style={{ textDecoration: "none" }}
                    className="d-flex justify-content-center"
                  >
                    <CButton
                      color="primary"
                      className="mt-3"
                      variant="outline"
                      tabIndex={-1}
                    >
                      Contact info@atgeirsolutions.com
                    </CButton>
                  </a>
                </div>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
}
