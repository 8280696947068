import { configureStore } from '@reduxjs/toolkit'
import userDetailsReducer from './userDetail'
import sideBarReducer from './sideBar'
import currentTableReducer from './currentTable'
import configDetailsReducer from './configDetails'

const reducer = {
  reducer: {
    loginUserDetails: userDetailsReducer,
    sideBar: sideBarReducer,
    currentlySelectedTable:currentTableReducer,
    configDetails:configDetailsReducer

  }
}
export default reducer