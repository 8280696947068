import jwtDecode from "jwt-decode";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import ValidateEmail from "../services/emailvalidation.service";
import userDetailService from "../services/user.detail.service";
import requestflowService from "../services/requestflow.service";
//redux import
import { useDispatch } from "react-redux";
import { updateUserUuid } from "../redux/userDetail";
import UseUUID from "../components/App/UseUUID";
import UseToken from "../components/App/UseToken";
import { UseUserDetails } from "../components/App/useUserDetails";
import { CAlert } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilCheckCircle } from "@coreui/icons";
import EnterpriseUUID from "../components/App/EnterpriseUUID";
import { UseDomainDetails } from "../components/App/UseDomainDetails";
import signup_licenseService from "../services/signup_license.service";
import domainListService from "../services/domain.list.service";
import { GoogleLogin } from "@react-oauth/google";
import { UseUserPlanDetails } from "../components/App/UseUserPlanDetails";

const ServiceType = {
  ENTERPRISE: "ENTERPRISE",
  DOMAIN: "DOMAIN",
  PRODUCT: "PRODUCT",
  ENTERPRISE_USER: "ENTERPRISE_USER",
  DATA_SOURCE_USER: "DATA_SOURCE_USER",
};
const AuthType = {
  GOOGLE: "google",
};
export default function GoogleSignIn(props) {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { accessToken, setAccessToken } = UseToken();
  const { userDetails, setUserDetails } = UseUserDetails();
  const { enterpriseUUID, setEnterpriseUUID } = EnterpriseUUID();
  const { domainDetails, setDomainDetails } = UseDomainDetails();
  const { userPlanDetails, setUserPlanDetails } = UseUserPlanDetails();

  const { UUID, setUUID } = UseUUID();

  const handleCallBackResponse = async (response) => {
    props.setErrorVisible(false);
    props.setVisible(false);

    const userObject = jwtDecode(response.credential);

    setAccessToken(response.credential);

    var emailres = await emailvalidation(userObject.email);
    // console.log("emailResp", emailres)
    if (emailres.count == 0) {
      // props.handleActiveKey(3,userObject.email,userObject.name);
      navigate("/account-setup", {
        state: { email: userObject.email, name: userObject.name },
      });
    } else {
      getSignInUserDetails(userObject.email);
    }
  };
  const getLicense = async (userdetails) => {
    if (userdetails.request_type == "ENTERPRISE") {
      var repo = await signup_licenseService.GetUserLicense(userdetails.uuid);
      if (repo != undefined) {
        if (repo.length > 0) {
          let config = JSON.parse(repo[0].properties);

          return config;
        }
      }
    } else if (userdetails.request_type == "ENTERPRISE_USER") {
      var repo = await signup_licenseService.GetUserLicense(
        userdetails.parent_uuid
      );
      if (repo != undefined) {
        if (repo.length > 0) {
          let config = JSON.parse(repo[0].properties);

          return config;
        }
      }
    } else if (userdetails.request_type == "DOMAIN") {
      const respons = await domainListService.getParentId(
        userdetails.parent_uuid
      );
      if (respons[0].parent_uuid == "null" || respons[0].parent_uuid == null) {
        var repo = await signup_licenseService.GetUserLicense(
          userdetails.parent_uuid
        );
        if (repo != undefined) {
          if (repo.length > 0) {
            let config = JSON.parse(repo[0].properties);

            return config;
          }
        }
      } else {
        var repo = await signup_licenseService.GetUserLicense(
          respons[0].parent_uuid
        );
        if (repo != undefined) {
          if (repo.length > 0) {
            let config = JSON.parse(repo[0].properties);

            return config;
          }
        }
      }
    } else if (userdetails.request_type == "DATA_SOURCE_USER") {
      const respos = await domainListService.getParentId(
        userdetails.parent_uuid
      );

      const respons = await domainListService.getParentId(
        respos[0].parent_uuid
      );

      if (respons[0].parent_uuid == "null" || respons[0].parent_uuid == null) {
        var repo = await signup_licenseService.GetUserLicense(
          respos[0].parent_uuid
        );
        if (repo != undefined) {
          if (repo.length > 0) {
            let config = JSON.parse(repo[0].properties);
            return config;
          }
        }
      } else {
        var repo = await signup_licenseService.GetUserLicense(
          respons[0].parent_uuid
        );
        if (repo != undefined) {
          if (repo.length > 0) {
            let config = JSON.parse(repo[0].properties);

            return config;
          }
        }
      }
    }
  };
  const getSignInUserDetails = async (email) => {
    const response = await userDetailService.getUserDetails(email);
    // console.log("user details", response);
    if (response != undefined) {
      const licenseDetails = await getLicense(response);
      let expiryDate = new Date(licenseDetails.end_date).getTime();
      let currentDate = new Date().getTime();
      if (currentDate <= expiryDate && response["is_active"] !== "N") {
        setUserPlanDetails(
          licenseDetails.start_date,
          licenseDetails.end_date,
          licenseDetails.plan
        );
        setUserDetails(
          response.parent_uuid,
          response.email,
          response.request_type,
          response.auth_type,
          response.owner
        );
        setUUID(response["uuid"]);
        dispatch(updateUserUuid(response["uuid"]));
        // console.log("google sign in resp", response);
        if (response["auth_type"] == AuthType.GOOGLE) {
          if (
            (response["request_type"] === ServiceType.ENTERPRISE &&
              response["is_active"] === "Y") ||
            (response["request_type"] === ServiceType.ENTERPRISE_USER &&
              response["is_active"] === "Y")
          ) {
            setEnterpriseUUID(response["uuid"]);
            setUUID(null);
            let path = "/datageir/enterprise-dashboard";
            navigate(path, { state: { data: response } });
            var action_id = 8;
            var actionFlow = await requestflowService.registerRequest(
              action_id,
              response["uuid"]
            );
          } else if (
            response["request_type"] === ServiceType.DOMAIN ||
            response["request_type"] === ServiceType.DATA_SOURCE_USER
          ) {
            setDomainDetails(
              response.service_name,
              response.email,
              response.description,
              response.owner
            );
            let path = "/datageir/data-sources";
            navigate(path, { state: { data: response } });
            var action_id = 14;
            var actionFlow = await requestflowService.registerRequest(
              action_id,
              response["uuid"]
            );
          } else if (response["request_type"] === ServiceType.PRODUCT) {
            let path = "/datageir/productowner";
            navigate(path, { state: { data: response } });
            var action_id = 22;
            var actionFlow = await requestflowService.registerRequest(
              action_id,
              response["uuid"]
            );
          } else if (
            response["request_type"] === ServiceType.ENTERPRISE &&
            response["is_active"] === "N"
          ) {
            props.setErrorVisible(false);
            props.setVisible(false);
            props.setRejectVisible(true);
          } else {
            props.setErrorVisible(false);
            props.setRejectVisible(false);
            props.setVisible(true);
            console.log("user not active");
          }
        } else {
          // console.log("response", response);
          if (
            response["request_type"] === ServiceType.ENTERPRISE &&
            response["is_active"] === "Y"
          ) {
            props.setUserExistVisible(true);
            props.setErrorVisible(false);
            props.setVisible(false);
            props.setRejectVisible(false);
          } else if (
            response["request_type"] === ServiceType.ENTERPRISE &&
            response["is_active"] === "N"
          ) {
            props.setErrorVisible(false);
            props.setVisible(false);
            props.setRejectVisible(true);
            props.setUserExistVisible(false);
          } else {
            props.setUserExistVisible(false);
            props.setErrorVisible(false);
            props.setVisible(true);
            props.setRejectVisible(false);
          }
        }
      } else if (expiryDate != Number && response["is_active"] !== "Y") {
        props.setVisible(true);
      } else {
        props.setLicenseExpired(true);
      }
    }
  };
  const emailvalidation = async (email) => {
    var emailresp = await ValidateEmail.checkEmail(email.toLowerCase());
    return emailresp;
  };
  // function initGoogle() {
  //   try {
  //     window.google.accounts.id.initialize({
  //       client_id:
  //         "971708092056-ne2m0vkq9qjh195s50che03rh2q5fm4m.apps.googleusercontent.com",
  //       callback: handleCallBackResponse,
  //     });
  //     window.google.accounts.id.renderButton(document.getElementById("signin"), {
  //       theme: "outline",
  //       size: "medium",
  //       width: "318",
  //       logo_alignment: "center",
  //       ux_mode: "redirect",
  //       text: props.gText,
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }
  // useEffect(() => {
  //   initGoogle();
  // }, []);

  return (
    <div>
      <GoogleLogin
        onSuccess={(credentialResponse) => {
          handleCallBackResponse(credentialResponse);
        }}
        onError={() => {
          console.log("Login Failed");
        }}
      />
    </div>
  );
}
