import React, { useEffect, useState } from "react";
import {
  CAlert,
  CSpinner,
  CNav,
  CTabPane,
  CTabContent,
  CNavItem,
  CNavTitle,
  CNavLink,
  CCardTitle,
  CButton,
  CContainer,
  CRow,
  CCol,
  CCard,
  CCardBody,
  CForm,
  CInputGroup,
  CInputGroupText,
  CFormInput,
  CCardGroup,
  CHeaderDivider,
} from "@coreui/react";
import Input from "./Input";
import { Link, useNavigate } from "react-router-dom";
import GoogleSignIn from "../pages/GoogleSignIn";
import enterpriseProfileImg from "../assets/enterprise_profile.png";

export default function UserLogin({
  fields,
  loginState,
  handleSubmit,
  handleChange,
  isLoading,
  errorVisible,
  setErrorVisible,
  loginType,
  visible,
  setVisible,
  handleActiveKey,
  rejectVisible,
  setRejectVisible,
  setUserNotExistError,
  userNotExistError,
  userExistVisible,
  setUserExistVisible,
  licenseExpired,
  setLicenseExpired,
}) {
  const navigate = useNavigate();
  return (
    // <CContainer>
    <CCol md={12} className="justify-content-center w-100">
      <CCardGroup className="m-0">
        <CCard className="px-4 py-2 rounded-0">
          <CCardBody>
            <CForm
              style={{ display: "block" }}
              onSubmit={handleSubmit}
              autoComplete="off"
            >
              <CCardTitle style={{ fontSize: 20 }}>
                {" "}
                DataGeir™ Sign In{" "}
              </CCardTitle>
              {/* <p className="text-medium-emphasis">Don't have an account yet? Sign Up</p> */}
              <p className="text-medium-emphasis">
                {" "}
                {loginType == "user"
                  ? "Sign In to your User account"
                  : " Sign In to your Admin account"}
              </p>
              {fields.map((field) => (
                <Input
                  key={field.id}
                  handleChange={handleChange}
                  value={loginState[field.id]}
                  labelText={field.labelText}
                  labelFor={field.labelFor}
                  id={field.id}
                  name={field.name}
                  type={field.type}
                  isRequired={field.isRequired}
                  placeholder={field.placeholder}
                  icon={field.icon}
                  autofocus={field.autofocus}
                />
              ))}
              <CCol className="d-flex justify-content-center p-0 m-0 mt-1">
                <Link className="text-medium-emphasis mt-1" style={{ textDecoration: "none" }} to={"/forgot-password"}>Forgot Password?</Link>
              </CCol>
              <CRow className="mt-3 d-flex flex-column align-items-center gap-2">
                <CCol
                  sm={12}
                  md={12}
                  className="m-0 p-0 d-flex align-items-center justify-content-center text-muted"
                >
                  <CButton
                    disabled={isLoading}
                    type="submit"
                    size="sm"
                    className="d-flex gap-1 align-items-center sign-in-button justify-content-center mx-4"
                  >
                    {isLoading ? (
                      <CSpinner component="span" size="sm" aria-hidden="true" />
                    ) : null}{" "}
                    Sign In
                  </CButton>
                </CCol>

                {loginType === "user" ? (
                  <>
                    <CCol className="m-0 p-0 small d-flex justify-content-center align-items-center gap-2">
                      <div className="border border-light divider-size"></div>{" "}
                      Or{" "}
                      <div className="border border-light divider-size"></div>
                    </CCol>
                    <CCol className="m-0 p-0 max-width-fit">
                      <GoogleSignIn
                        setVisible={setVisible}
                        handleActiveKey={handleActiveKey}
                        setErrorVisible={setErrorVisible}
                        setRejectVisible={setRejectVisible}
                        setUserExistVisible={setUserExistVisible}
                        setLicenseExpired={setLicenseExpired}
                      />
                    </CCol>
                    <div className="d-flex justify-content-center gap-2 align-items-center mb-2 mt-2">
                      <p className="text-medium-emphasis m-0 p-0">
                        Create a new account?
                      </p>
                      <div
                        className="border border-light rounded px-2 py-1 node"
                        onClick={() => {
                          setErrorVisible(false);
                          setRejectVisible(false);
                          setVisible(false);
                          setUserExistVisible(false);
                          handleActiveKey(1);
                        }}
                      >
                        Sign up
                      </div>
                    </div>
                  </>
                ) : null}
              </CRow>
              <br></br>
              <CAlert
                color="danger"
                className="d-flex align-items-center"
                visible={errorVisible}
                onClose={() => setErrorVisible(false)}
                dismissible
              >
                Incorrect Username or Password
              </CAlert>
              <CAlert
                color="danger"
                className="d-flex align-items-center"
                visible={userNotExistError}
                onClose={() => setUserNotExistError(false)}
                dismissible
              >
                User Does Not Exist
              </CAlert>
              <CAlert
                color="danger"
                className="d-flex align-items-center"
                visible={visible}
                onClose={() => setVisible(false)}
                dismissible
              >
                Awaiting Admin approval.
              </CAlert>

              <CAlert
                color="danger"
                className="d-flex align-items-center"
                visible={rejectVisible}
                onClose={() => setRejectVisible(false)}
                dismissible
              >
                Enterprise request rejected by Admin
              </CAlert>
              <CAlert
                color="danger"
                className="d-flex align-items-center"
                visible={userExistVisible}
                onClose={() => setUserExistVisible(false)}
                dismissible
              >
                User Already Exists
              </CAlert>
              <CAlert
                color="danger"
                className="d-flex align-items-center"
                visible={licenseExpired}
                onClose={() => setLicenseExpired(false)}
                dismissible
              >
                License Expired. Please reach out to our support team for
                immediate assistance and license renewal options.
              </CAlert>
            </CForm>
          </CCardBody>
        </CCard>
        {/* <CCard
            className="text-white bg-primary py-5"
            style={{ width: "44%" }}
          >
            <CCardBody className="text-center">
              <div>
                <h5 style={{ fontSize: "20px" }}>DataGeir™</h5>
                <p>
                  DataGeir™ is a solution package built for the cloud that helps
                  businesses get the most value from their scattered, siloed
                  data. Our products' cloud-based data mesh framework guarantees
                  hyper-scalable data asset support and drives competitive
                  advantage.
                </p>
                <Link to="">
                  <CButton className="mt-3" active tabIndex={-1}>
                    Contact info@atgeirsolutions.com
                  </CButton>
                </Link>
              </div>
            </CCardBody>
          </CCard> */}
      </CCardGroup>
    </CCol>
    // </CContainer>
  );
}
