import React, { useState } from "react";
import { CInputGroup, CInputGroupText, CFormInput } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilLowVision } from "@coreui/icons";

export default function Input({
  handleChange,
  value,
  id,
  name,
  type,
  isRequired = false,
  placeholder,
  icon,
  autoComplete,
  autofocus
}) {
  const [visible, setVisible] = useState(false);
  return (
    <CInputGroup className="mt-3 position-relative">
      <CInputGroupText className="bg-white">
        <CIcon icon={icon} size="sm" className="color" />
      </CInputGroupText>
      <CFormInput
        size="sm"
        id={id}
        type={type == "password" && !visible ? type : "text"}
        value={value}
        name={name}
        placeholder={placeholder}
        required={isRequired}
        onChange={handleChange}
        autoComplete={autoComplete}
        autoFocus={autofocus?"autofocus":null}
        // valid
        // feedback
      />

      {type == "password" ? (
        <CIcon
          className={`node ${visible ? "text-primary" : ""}`}
          style={{
            position: "absolute",
            right: "5px",
            top: "7px",
            zIndex: 200,
          }}
          icon={cilLowVision}
          onClick={() => setVisible(!visible)}
        />
      ) : null}
    </CInputGroup>
  );
}
