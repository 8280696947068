import axios from "axios";
const config = require('../config/config');
const API_URL=config.api_url

class RequestFlow {

  registerRequest(action_id, enterprise_uuid) {
    // console.log("API url:",API_URL)
  
    try {
      var data = JSON.stringify({
        "action_id": action_id,
        "uuid": enterprise_uuid
      });

      var config = {
        method: 'post',
        url: API_URL + "/v1/postgres/request_flow/add",
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };
      // console.log("API config:",config)

     return axios(config)
        .then(function (response) {
          // console.log("admin",JSON.stringify(response.data));
          return response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
      return error;
    }
  }
}

export default new RequestFlow();